export const categories =
  [
    {
      "title": "Young Adult",
      "path": "/category/young-adult"
    },
    {
      "title": "Fantasy",
      "path": "/category/fantasy"
    },
    {
      "title": "Sci-Fi",
      "path": "/category/science-fiction"
    },
    {
      "title": "Thrillers",
      "path": "/category/suspense-and-thriller"
    },
    {
      "title": "Romance",
      "path": "/category/romance"
    },
    {
      "title": "Comics",
      "path": "/category/comics-and-graphic-novels"
    },
    {
      "title": "Historical Fiction",
      "path": "/category/historical-fiction"
    },
    {
      "title": "Biography",
      "path": "/category/biography-and-memoir"
    },
    {
      "title": "Kids' Books",
      "path": "/category/kids-books"
    },
    {
      "title": "History",
      "path": "/category/history"
    },
    {
      "title": "Horror",
      "path": "/category/horror"
    },
    {
      "title": "Mystery",
      "path": "/category/mystery"
    },
    {
      "title": "Self-Help",
      "path": "/category/self-help"
    },
    {
      "title": "Business",
      "path": "/category/business-and-economics"
    },
    {
      "title": "General Fiction",
      "path": "/category/general-fiction"
    },
    {
      "title": "Poetry",
      "path": "/category/poetry"
    },
    {
      "title": "Cookbooks",
      "path": "/category/cookbooks"
    },
    {
      "title": "See All Categories",
      "path": "/categories"
    }
  ];
