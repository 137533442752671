'use client';

import { ICartItem } from '@/clients/types';
import { UserProfile, Book, Title, Discount, ShippingItem } from 'types';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { images, formatMoney, possessive } from 'sdk';
import { realtimeDocumentListener } from '@/clients/firebase/client';
import Firebase from '@/clients/firebase/firebase-react-query';
import CloseSVG from '@/assets/svg/icons/close';
import Button from '@/components/button';
import Pill from '@/components/pill';
import styles from '@/styles/components/cart-components/sold-by-card.module.scss';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Links from '@/lib/helpers/link-helper';
import { useGlobalState } from '@/state';
import { ActionType } from '@/state/types';
import SoldBooks from './sold-books';
import useCart from '@/lib/hooks/use-cart';
import Card from '@/components/card';
import { Flex } from '@/components/layout/flex';
import CheckCircleSVG from '~/assets/svg/icons/check-circle';

interface RecommendedBookProps {
  book: Book;
  bookToReplace: Book;
  small?: boolean;
}
function RecommendedBook({ book, bookToReplace, small }: RecommendedBookProps) {
  const [loading, setLoading] = useState<boolean>(false);

  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const { replaceCartItem } = useCart();

  const handleReplace = async () => {
    setLoading(true);
    try {
      await replaceCartItem({
        oldItem: bookToReplace,
        newItem: book,
      });
      // if we're at checkout then replace old id with new id
      if (pathname?.startsWith('/checkout')) {
        // get current array of ids
        let ids = typeof searchParams.get('ids') === 'string' ? [searchParams.get('ids')] : searchParams.get('ids');
        const cid = searchParams.get('cid') as string;
        if (!ids) return;
        const index = ids.indexOf(bookToReplace.id);
        if (index >= 0) {
          ids = Array.isArray(ids) ? ids : [ids]; // Ensure ids is always an array
          ids[index] = book.id;
          router.replace(Links.checkout({ ids, cid }).url);
        }
      }
    } catch (e) {
      // handle the error
    }
    setLoading(false);
  };

  return (
    <div
      className={classNames(styles['rec-wrapper'], {
        [styles['rec-wrapper-small']]: small,
      })}
    >
      <div className={styles['rec-callout-title']}>The copy you chose is sold</div>
      <div className={styles['rec-callout-body']}>Here is another you might like</div>
      <div className={classNames(styles['rec-container'])}>
        <Link href={Links.book(book.id).show.pathname} passHref legacyBehavior>
          <div className={styles['rec-image']}>
            <Image src={images(book.photo_path).book.tile} alt={book.title} width={70} height={80} unoptimized />
          </div>
        </Link>
        <div className={styles.details}>
          <div className={classNames(styles.title, styles['rec-title'])}>{book.title}</div>
          <div className={styles['price-wrapper']}>
            <div className={classNames(styles.text, styles['rec-price'])}>
              {formatMoney(book.amount) + ' '}
              <span className={classNames(styles['strike-thru'], styles['rec-price-st'])}>
                {book.retail_amount && book.retail_amount > book.amount ? formatMoney(book.retail_amount) : ''}
              </span>
            </div>
          </div>
        </div>
        <div className={styles['rec-button']}>
          <Button
            text='Replace'
            size='xtra-small'
            style='secondary'
            loading={loading}
            disabled={loading}
            onPress={handleReplace}
          />
        </div>
      </div>
    </div>
  );
}

interface BookCardProps {
  item: ICartItem;
  small?: boolean;
  seller?: UserProfile;
  numItems: number;
  showBuyNow: boolean;
  removeFromCart?: (item: ICartItem) => Promise<void>;
  markAsSold?: (item: ICartItem, sold?: boolean) => Promise<void>;
  updateBookState?: React.Dispatch<React.SetStateAction<Book[]>>;
}
export function BookCard({
  item,
  small,
  seller,
  showBuyNow,
  removeFromCart,
  markAsSold,
  updateBookState,
}: BookCardProps) {
  const { state } = useGlobalState();

  const [recommendation, setRecommendation] = useState<Book>(null);
  // handlers
  const handleRemoveFromCart = async (item: ICartItem) => {
    await removeFromCart(item);
  };

  useEffect(() => {
    let mounted = true;
    realtimeDocumentListener('books', item.id, (doc) => {
      if (!mounted || !markAsSold) return;
      if (doc.exists() && item.sold) {
        markAsSold(item, false);
        if (updateBookState) {
          updateBookState((currState) => {
            const newState = [...currState];
            const index = newState.findIndex((b) => b.id === item.id);
            if (index >= 0) {
              newState[index] = { ...newState[index], sold: false } as Book;
              return newState;
            }
            return currState;
          });
        }
      } else if (!doc.exists() && !item.sold) {
        markAsSold(item);
        if (updateBookState) {
          updateBookState((currState) => {
            const newState = [...currState];
            const index = newState.findIndex((b) => b.id === item.id);
            if (index >= 0) {
              newState[index] = { ...newState[index], sold: true } as Book;
              return newState;
            }
            return currState;
          });
        }
      }
    });
    return () => {
      mounted = false;
    };
  }, [item.id]);

  // If sold is true then set a listener on the title doc. Get the selected listing.
  useEffect(() => {
    let mounted = true;
    if (item.sold && item.title_id) {
      // get selected listing from title doc
      realtimeDocumentListener(`titles`, item.title_id, (doc) => {
        const data = doc.data() as Title;
        if (data?.selected_listing && data?.selected_listing.id !== item.id && mounted) {
          setRecommendation(data.selected_listing);
        }
      });
    }
    return () => {
      mounted = false;
    };
  }, [item.id, item.sold]);

  return (
    <>
      <div
        key={item.id}
        className={classNames(styles['item-container'], {
          [styles['item-container-small']]: small,
        })}
      >
        <Link href={Links.book(item.id).show.pathname} passHref legacyBehavior>
          <div className={styles['image-container']}>
            <Image
              src={item.photo_path ? images(item.photo_path).book.tile : item.thumb_url || item.photo_url}
              alt={item.title}
              width={70}
              height={80}
              unoptimized
            />
            {item.sold ? <div className={styles.sold}>Sold</div> : null}
          </div>
        </Link>
        <div className={styles.details}>
          {small ? <div className={styles['text-seller']}>Sold by {seller?.name}</div> : null}
          <div className={styles['title-container']}>
            <div className={styles.title}>{item.title}</div>
            {item.sold && <div className={styles['sold-wrapper']}>Sold</div>}
          </div>
          <div className={styles['price-wrapper']}>
            <div className={styles.text}>
              {formatMoney(item.amount) + ' '}
              <span className={styles['strike-thru']}>
                {item.retail_amount && item.retail_amount > item.amount ? formatMoney(item.retail_amount) : ''}
              </span>
            </div>
          </div>
          {item.offer_applied && (
            <Flex direction='row' align='center' mt={0.25}>
              <div className={styles['offer-applied']}>
                <CheckCircleSVG />
              </div>
              <span className={styles['offer-applied-text']}>Seller offer applied</span>
            </Flex>
          )}
        </div>

        <div className={styles['close-container']}>
          {Boolean(removeFromCart) ? (
            <div className={styles['close-icon']} onClick={() => handleRemoveFromCart(item)}>
              <CloseSVG />
            </div>
          ) : (
            <div />
          )}
          {small && showBuyNow ? (
            <div>
              <Button
                text='Buy Now'
                size='xtra-small'
                disabled={item.sold}
                link={{ href: Links.checkout({ ids: [item.id], cid: state?.user?.data?.uid }).url }}
              />
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
      {item.sold && recommendation ? (
        <RecommendedBook book={recommendation as ICartItem} bookToReplace={item} small={small} />
      ) : null}
    </>
  );
}

interface SoldByProps {
  seller_id: string;
  items: ICartItem[] | Book[];
  removeFromCart?: (item: ICartItem) => Promise<void>;
  markAsSold?: (item: ICartItem) => Promise<void>;
  showBuyNow: boolean;
  shipping?: ShippingItem | null;
  style?: 'small';
  updateBookState?: React.Dispatch<React.SetStateAction<Book[]>>;
  discounts: Discount[];
  noShadow?: boolean;
}
export default function SoldByCard({
  seller_id,
  items,
  removeFromCart,
  markAsSold,
  showBuyNow,
  style,
  shipping,
  updateBookState,
  discounts,
  noShadow,
}: SoldByProps) {
  const small = style === 'small';

  const { state, dispatch } = useGlobalState();

  // calculate total price
  const total = items.map((item) => item.amount).reduce((p, c) => p + c);
  // get seller data
  const { data } = Firebase<UserProfile>().fetchCollectionDocByUid(seller_id, 'users');

  const AmountPill = ({ text, textColor }: { text: string; textColor?: 'primary' | 'copyDark'; }) => (
    <div className={classNames(styles['pill-wrapper'], styles['no-margin'])}>
      <Pill textColor={textColor} text={text} small={true} style='secondary' />
    </div>
  );

  const shippingAmount = shipping?.shipping_amount || 0;
  const shippingText = shipping?.shipping_amount === 0 ? 'FREE shipping' : `+${formatMoney(shippingAmount)} shipping`;

  const containsSoldBook = useMemo(() => Boolean(items.find((item) => (item as ICartItem).sold)), [items]);

  const handleBuyNow = useCallback(() => {
    if (containsSoldBook) {
      dispatch({
        type: ActionType.ADD_MODAL_DATA,
        payload: {
          title: 'Sold Book',
          component: <SoldBooks items={items as ICartItem[]} removeFromCart={removeFromCart} type='buy-now' />,
        },
      });
    }
  }, [containsSoldBook, dispatch, items]);

  const IS_INSURED =
    shipping?.shipping_type?.pango_insurance || shipping?.shipping_type?.service_code === 'usps_priority_mail';

  const discountAmount = discounts.reduce((total, discount) => (total += discount.amount), 0);
  return (
    <Card className={styles.container} noShadow={noShadow}>
      {!small ? (
        <div className={styles['text-top']}>
          Sold by{' '}
          <span>
            <Link href={Links.bookstore(data)?.show?.pathname} passHref className={styles['seller-link']}>
              {data?.name}
            </Link>
          </span>
        </div>
      ) : null}
      {items.map((item) => (
        <BookCard
          key={item.id}
          item={item}
          small={small}
          seller={data}
          numItems={items?.length}
          showBuyNow={showBuyNow}
          removeFromCart={removeFromCart}
          markAsSold={markAsSold}
          updateBookState={updateBookState}
        />
      ))}
      {shipping?.shipping_amount ? <AmountPill text={shippingText} /> : null}
      {shipping?.shipping_type?.service_code === 'usps_priority_mail' ? (
        <AmountPill text={'FREE Priority Mail shipping'} textColor='primary' />
      ) : null}
      {shipping?.shipping_type?.pango_insurance ? <AmountPill text={'FREE Shipping'} /> : null}
      {discounts.map(({ amount, description, conditionMessage, seller, type }) => (
        <AmountPill
          key={description + seller.id}
          text={
            conditionMessage
              ? `${conditionMessage} for ${description}`
              : type === 'shipping' && IS_INSURED
                ? null
                : type === 'shipping'
                  ? description
                  : `-${formatMoney(amount)} discount (${description})`
          }
        />
      ))}
      <div className={classNames(styles['buy-now-container'], { [styles['mt-1']]: items?.length > 1 })}>
        {shipping && (
          <div className={styles.text}>
            Total for {possessive(data?.name)} shop
            <span className={styles.total}> {formatMoney(total + shippingAmount - discountAmount)}</span>
          </div>
        )}
        {showBuyNow && !small ? (
          <div>
            <Button
              text='Buy Now'
              size='small'
              onPress={handleBuyNow}
              link={
                !containsSoldBook && {
                  href: Links.checkout({ ids: items.map((item) => item.id), cid: state?.user?.data?.uid }).url,
                }
              }
            />
          </div>
        ) : null}
      </div>
    </Card>
  );
}
