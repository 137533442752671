'use client';

import { useState, useEffect } from 'react';
import classNames from 'classnames';
import Link from 'next/link';
import DropDown from './dropdown';
import { categories } from '~/assets/data/categories';
import { navUrls } from '~/assets/data/nav-urls';
import ArrowDownBtnSVG from '~/assets/svg/icons/arrow-down-btn';
import ArrowUpBtnSVG from '~/assets/svg/icons/arrow-up-btn';
import COLORS from '~/lib/helpers/color-helper';

import { useGlobalState } from "~/state";
import { ActionType } from "~/state/types";

import styles from '../styles/components/categories.module.scss';

export default function Categories() {
  const [visibleTextArray, setVisibleTextArray] = useState([]);
  const [dropdownArray, setDropdownArray] = useState([]);
  const [openDropdown, setOpenDropdown] = useState(false);

  const { dispatch } = useGlobalState();

  const CATEGORY_ITEM_WIDTH = 115;
  const DROPDOWN_ITEM_WIDTH = 150;
  const MOBILE_BREAKPOINT = 992;

  const getContainerWidth = () => {
    return document.getElementById('container').offsetWidth;
  };

  useEffect(() => {
    function handleResize() {
      // Get the container width
      const containerWidth = getContainerWidth();

      const IS_MOBILE = containerWidth < MOBILE_BREAKPOINT;

      // Calculate the maximum number of items that can fit in the container
      const maxItems = IS_MOBILE ? 7 : Math.floor((containerWidth - DROPDOWN_ITEM_WIDTH) / CATEGORY_ITEM_WIDTH); //even out category item width

      // Slice the array to fit the maximum number of items
      setVisibleTextArray(categories.slice(0, maxItems));
      setDropdownArray(categories.slice(maxItems));
    }

    // Call handleResize initially and on window resize
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [categories]);



  const renderDropdown = () => {

    const IS_MOBILE = getContainerWidth() < MOBILE_BREAKPOINT;

    const handleOpenMore = () => {
      if (IS_MOBILE) {
        dispatch({
          type: ActionType.ADD_MODAL_DATA,
          payload: {
            title: 'More Categories',
            component: DropdownContainer
          }
        });
      } else {
        setOpenDropdown(true);
      }
    };

    const DropdownContainer = (
      <div className={classNames(styles['dropdown-container'])}>
        {
          dropdownArray.map(category => {
            return (
              <Link key={category.title} href={category.path} legacyBehavior>
                <div onClick={() => { if (IS_MOBILE) dispatch({ type: ActionType.FLUSH_MODAL_DATA }); }} className={classNames(styles['dropdown-container-item'])}>
                  <a className={classNames(styles['link-items'], styles['link-items-dropdown'], styles['link-items-text'])}>{category.title}</a>
                </div>
              </Link>
            );
          })
        }
        <div className={styles.divider} />
        {
          navUrls.map(navUrl => {
            return (
              <Link key={navUrl.title} href={navUrl.path} legacyBehavior>
                <div onClick={() => { if (IS_MOBILE) dispatch({ type: ActionType.FLUSH_MODAL_DATA }); }} className={classNames(styles['dropdown-container-item'])}>
                  <a className={classNames(styles['link-items'], styles['link-items-dropdown'], styles['link-items-text'])}>{navUrl.title}</a>
                </div>
              </Link>
            );
          })
        }
      </div>
    );
    return (
      <div className={classNames(styles['more-container'])} onClick={handleOpenMore}  >
        <div
          className={classNames(styles['more-link'])}
          style={{ width: IS_MOBILE ? 100 : DROPDOWN_ITEM_WIDTH }}
        >
          <h3
            className={classNames(styles['link-items'], styles['link-items-text'],
              { [styles['link-items-active']]: openDropdown })}
          >
            More Categories
          </h3>
          {<div className={styles.arrow}>{openDropdown ? <ArrowUpBtnSVG /> : <ArrowDownBtnSVG strokeColor={COLORS.copy} />}</div>}
        </div>
        <div style={{ position: 'absolute', bottom: 0, right: -10 }}>
          <DropDown open={openDropdown} onHide={() => setOpenDropdown(false)}>
            {DropdownContainer}
          </DropDown>
        </div>
      </div>
    );
  };



  return (
    <div id="container" className={styles.container}>
      {
        visibleTextArray.map(category => {
          return (
            <Link key={category.title} href={category.path} legacyBehavior>
              <div className={classNames(styles['visible-container'])}  >
                <a className={classNames(styles['link-items'], styles['link-items-text'])}>
                  {category.title}
                </a>
              </div>
            </Link>
          );
        })
      }
      {!!dropdownArray.length && renderDropdown()}
    </div>
  );


}