import React, { ComponentType, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  renderLink: (props: { url: string; text: string; }) => ReactNode;
  renderBold: (props: { text: string; }) => ReactNode;
  TextComponent: ComponentType<any>;
}
export function Markdown({ children, renderLink, renderBold, TextComponent }: Props) {
  if (!children) return null;

  const renderMarkdown = (text: string) => {
    // This regular expression matches the markdown link pattern
    const markdownLinkRegex = /\[([^\[]+)\]\(([^\)]+)\)/g;
    // This regular expression matches anything wrapped in triple backticks
    const codeBlockRegex = /```[\s\S]*?```/g;
    // This regular expression matches anything wrapped in double asterisks
    const boldMarkdownRegex = /(\*\*|__)(.*?)\1/g;
    // Remove code blocks
    const textWithoutCodeBlocks = text.replace(codeBlockRegex, '');

    const parts = textWithoutCodeBlocks.split(markdownLinkRegex);
    // parts will be an array of strings, where every 3 elements constitute the full link:
    // [textBeforeLink, linkText, linkUrl, textAfterLink, ...]
    return parts.map((part, index) => {
      // Every third element of the array is a link text, and the one following it is the URL
      const isLinkText = (index - 1) % 3 === 0;
      const isLinkUrl = (index - 2) % 3 === 0;
      if (isLinkText) {
        const url = parts[index + 1];
        return renderLink({ url, text: part });
      } else if (isLinkUrl) {
        // Skip URL since it's already processed
        return null;
      }
      // This is just text
      const boldParts = part.split(boldMarkdownRegex);
      return boldParts.map((boldPart, boldIndex) => {
        const isBoldIdentifier = boldPart === '**' || boldPart === '__';
        const isBold = boldParts?.[boldIndex - 1] === '**' || boldParts?.[boldIndex - 1] === '__';
        if (isBold) {
          return renderBold({ text: boldPart });
        } else if (isBoldIdentifier) {
          return null;
        }
        return boldPart;
      });
    });
  };

  return <TextComponent>{renderMarkdown(children as string)}</TextComponent>;
}
