import Button from './../button';
import COLORS from '~/lib/helpers/color-helper';
import CloseSVG from '~/assets/svg/icons/close';
import { useGlobalState } from '~/state';
import { ActionType } from '../../state/types';
import Link from 'next/link';
import styles from '../../styles/components/nav-menu.module.scss';
import LoadableImage from '../loadable-image';
import ProfileFilledIcon from '~/assets/svg/icons/profile-filled';
import ProfileSVG from '~/assets/svg/icons/profile';
import { images } from 'sdk';
import SessionForm from '../session-form';
import { useEffect, useRef } from 'react';
import Links from '~/lib/helpers/link-helper';
import classNames from 'classnames';
import Chevron from '~/assets/svg/icons/chevron';
import { Flex } from '../layout/flex';

export const TopNavMenu = ({
  currentPath,
  showSideMenu,
  setShowSideMenu,
  setShowCategories,
  showCategories,
  handleLogout,
}) => {
  const { state, dispatch } = useGlobalState();
  const dropdownContainerRef = useRef<HTMLDivElement>(null);
  const overlayRef = useRef<HTMLDivElement>(null);

  function userIsLoggedIn() {
    return Boolean(state.user.data.uid) && !state.user.data.isAnonymous;
  }

  function profilePhoto() {
    const photoURL = images(state.user.data?.photo_path).user.profileImage;
    return !!photoURL ? (
      <div className={styles['profile-photo']}>
        <LoadableImage alt='Profile Image' src={photoURL} width={250} height={250} />
      </div>
    ) : (
      <>{currentPath('profile') ? <ProfileFilledIcon /> : <ProfileSVG />}</>
    );
  }

  const closeMenu = () => {
    setShowSideMenu(false);
  };

  function handleClickOutsideMenu(e) {
    if (overlayRef?.current === e.target) {
      e.preventDefault();
      setShowSideMenu(false);
    }
  }

  useEffect(() => {
    if (showSideMenu) {
      document.addEventListener('mousedown', handleClickOutsideMenu);
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideMenu);
      document.body.style.overflow = '';
    };
  });

  function browseCategories() {
    if (!showCategories) return null;

    return (
      <div ref={dropdownContainerRef} className={styles['with-border']}>
        <div onClick={closeMenu} className={styles['categories-container']}>
          <nav>
            <Link href='/category/young-adult' passHref>
              Young Adult
            </Link>
          </nav>
          <nav>
            <Link href='/category/fantasy' passHref>
              Fantasy
            </Link>
          </nav>
          <nav>
            <Link href='/category/romance' passHref>
              Romance
            </Link>
          </nav>
          <nav>
            <Link href='/category/suspense-and-thriller' passHref>
              Thriller
            </Link>
          </nav>
          <nav>
            <Link href='/category/science-fiction' passHref>
              Sci-Fi
            </Link>
          </nav>
          <nav>
            <Link href='/category/historical-fiction' passHref>
              Historical Fiction
            </Link>
          </nav>
          <nav style={{ paddingBottom: '1rem' }}>
            <Link href={Links.categories.index} passHref>
              <Flex direction='row' align='center'>
                <div>
                  <h2>See all categories</h2>
                </div>

                <div style={{ paddingTop: '0.2rem' }}>
                  <Chevron direction='right' size={14} />
                </div>
              </Flex>
            </Link>
          </nav>
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        className={classNames(styles['side-menu'], {
          [styles['open']]: showSideMenu,
        })}
      >
        <Flex direction='column' justify='flex-end' align='flex-end'>
          <div
            onClick={() => {
              setShowSideMenu(false);
            }}
            className={styles['close-button']}
          >
            <CloseSVG strokeColor={COLORS.copyLight} width={25} />
          </div>
        </Flex>
        {userIsLoggedIn() ? (
          <section className={styles['profile-dropdown']} ref={dropdownContainerRef}>
            <div className={styles['profile-dropdown-user']}>
              <Link
                href='/account/settings'
                passHref
                className={styles['profile-dropdown-user-photo']}
                onClick={closeMenu}
              >
                {profilePhoto()}
                <Flex direction='column' align='flex-start' justify='center'>
                  <h2>{state.user.data?.name}</h2>
                  <Flex direction='row' align='center' justify='center'>
                    <div>
                      <h2 id={styles['view-account-hover']}>View Account</h2>
                    </div>
                    <div>
                      <Chevron direction='right' size={15} fillColor={COLORS.copyLight} />
                    </div>
                  </Flex>
                </Flex>
              </Link>
            </div>
          </section>
        ) : (
          <div className={styles['with-border']}>
            <Flex direction='row' justify='center' pt={0} pl={1} pr={1} pb={1}>
              <div style={{ marginRight: '10px', width: '80%' }}>
                <Button
                  onPress={() =>
                    dispatch({
                      type: ActionType.ADD_MODAL_DATA,
                      payload: {
                        title: 'Create an account',
                        component: <SessionForm display='signup' />,
                      },
                    })
                  }
                  text='Sign up'
                  size='xtra-small'
                  style='primary'
                ></Button>
              </div>
              <div style={{ width: '80%' }}>
                <Button
                  onPress={() =>
                    dispatch({
                      type: ActionType.ADD_MODAL_DATA,
                      payload: {
                        title: 'Welcome back!',
                        component: <SessionForm />,
                      },
                    })
                  }
                  text='Log in'
                  size='xtra-small'
                  style='secondary'
                ></Button>
              </div>
            </Flex>
          </div>
        )}

        <div className='browse-dropdown-container' onClick={() => setShowCategories(!showCategories)}>
          <section className={styles['browse-menu-dropdown']}>
            <div
              className={classNames(styles['browse-dropdown-flex'], styles['bold-text'], {
                [styles['with-border']]: !showCategories,
                [styles['browse-menu-open']]: showCategories,
              })}
            >
              <Flex direction='row' align='center' justify='space-between'>
                <h2> Browse by categories </h2>
                {showCategories ? (
                  <div>
                    <Chevron direction='up' size={20} />
                  </div>
                ) : (
                  <div>
                    <Chevron direction='down' size={20} />
                  </div>
                )}
              </Flex>
            </div>
            {showCategories && browseCategories()}
          </section>
        </div>

        <div onClick={closeMenu}>
          <div>
            <Link
              href={Links.collections.index.pathname}
              passHref
              className={`${styles['menu-item']} ${styles['bold-text']} ${styles['with-border']}`}
            >
              Collections
            </Link>
          </div>
          <div>
            <Link
              href={Links.sellerSearch.index}
              passHref
              className={`${styles['menu-item']} ${styles['bold-text']} ${styles['with-border']}`}
            >
              Bookstores
            </Link>
          </div>
          <div>
            <Link
              href={Links.shelves.index.pathname}
              passHref
              className={`${styles['menu-item']} ${styles['bold-text']} ${styles['with-border']}`}
            >
              Shelves
            </Link>
          </div>
          <div>
            <Link
              href={Links.heyPango.index.pathname}
              passHref
              className={`${styles['menu-item']} ${styles['bold-text']} ${styles['with-border']}`}
            >
              Hey Pango
            </Link>
          </div>
          <div>
            <Link
              href={Links.author.index.pathname}
              passHref
              className={`${styles['menu-item']} ${styles['bold-text']} ${styles['with-border']}`}
            >
              Popular Authors
            </Link>
          </div>
          <div>
            <Link
              href={Links.series.index.pathname}
              passHref
              className={`${styles['menu-item']} ${styles['bold-text']} ${styles['with-border']}`}
            >
              Popular Book Series
            </Link>
          </div>
          <div>
            <Link href='/sell' passHref className={`${styles['menu-item']} ${styles['bottom-menu-spacing']}`}>
              Sell your books
            </Link>
          </div>
          <div>
            <Link
              href={Links.about.index.pathname}
              passHref
              className={`${styles['menu-item']} ${styles['bottom-menu-spacing']}`}
            >
              About Us
            </Link>
          </div>
          <div>
            <Link
              href={Links.blog.index.pathname}
              passHref
              className={`${styles['menu-item']} ${styles['bottom-menu-spacing']}`}
            >
              Blog
            </Link>
          </div>
          <div>
            <Link
              href={Links.marketGuide.index}
              passHref
              className={`${styles['menu-item']} ${styles['bottom-menu-spacing']}`}
            >
              Market Guide
            </Link>
          </div>
          <div>
            <Link
              href={Links.helpCenter.index}
              passHref
              className={`${styles['menu-item']} ${styles['bottom-menu-spacing']}`}
            >
              Help Center
            </Link>
          </div>
          <div>
            <Link
              href={Links.helpCenter.contactUs}
              passHref
              className={`${styles['menu-item']} ${styles['bottom-menu-spacing']}`}
            >
              Contact Us
            </Link>
          </div>
          {userIsLoggedIn() ? (
            <div style={{ paddingBottom: '2.5rem' }}>
              <a onClick={handleLogout} className={styles['menu-item']}>
                Logout
              </a>
            </div>
          ) : null}
        </div>
      </div>
      <div
        ref={overlayRef}
        className={classNames(styles['overlay'], {
          [styles['show']]: showSideMenu,
        })}
      ></div>
    </>
  );
};
