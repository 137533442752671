import classNames from 'classnames';
import moment from 'moment';
import type { GetServerSidePropsContext, NextPage } from 'next';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useMeasure } from 'react-use';
import { useState, useCallback, useEffect } from 'react';

import { fetchCollectionDocByPath } from '~/clients/firebase/client';

import { images, truncate } from 'sdk';
import { withUser } from '~/lib/user';
import useMessages from '../../../lib/hooks/use-messages';
import { Links } from '~/lib/helpers/link-helper';

import NewMessageSVG from '../../../assets/svg/icons/new-msg';
import ChevronRightSVG from '../../../assets/svg/icons/chevron-right';
import MessageSVG from '../../../assets/svg/icons/message';

import PageMetaData from '~/components/page-meta-data';
import Button from '~/components/button';
import { BookTileSmall } from '~/components/tiles/book';
import CondensedImage from '~/components/settings/condensed-image';
import StatusBadge from '~/components/status-badge';

import InfiniteScroll from '~/components/infinite-scroll';

import { useGlobalState } from '../../../state';
import { ActionType } from '../../../state/types';
import { HydratedMessage, Message } from '../../../clients/types';
import { Book, Order } from 'types';

import styles from '../../../styles/pages/messages.module.scss';
import UserProfileImage from '~/components/user-profile-image';
import useInputFocus from '~/lib/hooks/use-input-focus';

interface MessageListItemProps {
  message: HydratedMessage;
  active: boolean;
  onPress: () => void;
  simple?: boolean;
}

export const calendarConfig = {
  sameDay: 'h:mm a',
  nextDay: '[Tomorrow]',
  nextWeek: 'dddd',
  lastDay: '[Yesterday]',
  lastWeek: 'MMMM Do',
  sameElse: 'M/D/YY',
};

export function MessageListItem(props: MessageListItemProps) {
  const { message, active, onPress, simple } = props;

  return (
    <div
      className={classNames(styles['msg-list-item-wrapper'], {
        [styles['msg-list-item-wrapper--active']]: active,
        [styles['msg-list-item-wrapper--simple']]: simple,
      })}
      onClick={onPress}
    >
      <div
        className={classNames(styles['user-list-item-wrapper'], {
          [styles['user-list-item-wrapper--simple']]: simple,
        })}
      >
        <div
          className={classNames(styles['unread-badge'], {
            [styles['unread-badge--visible']]: !message.read,
          })}
        />
        <div className={styles['msg-avatar']}>
          <Image
            src={message.user?.photo_path ? images(message.user?.photo_path).user.profileImage : message.user?.thumb_url || message.user?.photo_url || ''}
            alt={message.user?.name}
            unoptimized
            fill
          />
        </div>
        <div className={styles['msg-detail']}>
          <div className={styles['msg-name']}>{message.user?.name}</div>

          {simple ? (
            <div className={classNames(styles['msg-copy'], styles.simple)}>{truncate(message.message, 50)}</div>
          ) : (
            <div className={styles['msg-username']}>@{truncate(message.user?.username, 15)}</div>
          )}
          {!simple && <div className={styles['msg-copy']}>{truncate(message.message, 75)}</div>}
        </div>
        <div className={styles['msg-time']}>{moment(message.timestamp).fromNow(true)}</div>
      </div>
    </div>
  );
}



const AccountMessages: NextPage = () => {
  const { state, dispatch } = useGlobalState();
  const router = useRouter();
  const uid = state.user.data?.uid;
  const [messageInputRef, setMessageInputFocus] = useInputFocus();

  const [message, setMessage] = useState<string>('');
  const [book, setBook] = useState<Book>(null);
  const [order, setOrder] = useState<Order>(null);

  const [inputRef, { height: inputHeight }] = useMeasure();

  const {
    recentMessages,
    activeConversation,
    moreMsgs,
    moreRecentMsgs,
    fetchMoreMsgs,
    fetchMoreRecentMsgs,
    handleReadMessage,
    sendMessage,
  } = useMessages();

  async function handleSendMessage(e) {
    e.preventDefault();
    await sendMessage({
      text: message,
      book: book,
    });
    setMessage('');
    setBook(null);
  }

  useEffect(() => {
    document.onkeydown = (event) => {
      switch (event.code) {
        case 'Enter':
          handleSendMessage(event);
          break;
      }
    };
  });

  const renderBookPreview = () => {
    const bookId = router?.query?.bookId;
    if (bookId && typeof bookId === 'string') {
      const getBookData = async () => {
        const bookData = await fetchCollectionDocByPath<Book>(bookId, 'books');
        return bookData;
      };
      getBookData().then((res) => {
        setBook(res);
      });
    } else {
      setBook(null);
    }
  };

  const renderOrderPreview = () => {
    const orderId = router?.query?.orderId;
    if (orderId && typeof orderId === 'string') {
      const getOrderData = async () => {
        const orderData = await fetchCollectionDocByPath<Order>(orderId, 'completed_sales');
        return orderData;
      };
      getOrderData()
        .then((res) => {
          setOrder(res);
        })
        .catch((err) => { });
    } else {
      setOrder(null);
    }
  };

  useEffect(() => {
    //check query for if they are messaging about a book and preview if they are
    renderOrderPreview();
    renderBookPreview();
  }, [router?.query]);

  const handleNewMessage = () => {
    dispatch({
      type: ActionType.ADD_MODAL_DATA,
      payload: {
        title: 'New Message',
        message:
          'You can initiate messages from book pages, seller bookstores, as well as your recent purchases or sales.',
        showDismiss: true,
      },
    });
  };

  const handleScrollEnd = useCallback(() => {
    if (moreMsgs) {
      fetchMoreMsgs();
    }
  }, [moreMsgs, fetchMoreMsgs]);

  const handleRecentMsgsScrollEnd = useCallback(() => {
    if (moreRecentMsgs) {
      fetchMoreRecentMsgs();
    }
  }, [moreRecentMsgs, fetchMoreRecentMsgs]);

  function renderEmptyState() {
    if (activeConversation) return;

    return (
      <div className={styles['no-msgs-container']}>
        <div className={styles['no-msgs-outer-wrapper']}>
          <div className={styles['no-msgs-wrapper']}>
            <div className={styles['no-msgs-icon']}>
              <MessageSVG />
            </div>
            <h2 className={styles['no-msgs-title']}>Welcome to the Messenger!</h2>
            <p>{!recentMessages?.length ? 'Your messages will show up here.' : 'Select a message from the menu'}</p>
          </div>
        </div>
      </div>
    );
  }

  function renderActiveConversationHeader() {
    if (!activeConversation) return;

    return (
      <div className={styles['user-summary']}>
        <div className={styles['user-details']}>
          <div className={styles['user-avatar']}>
            <UserProfileImage user={activeConversation.user} />
          </div>
          <div className={styles['user-name-container']}>
            <div className={styles['user-name']}>{truncate(activeConversation?.user?.name, 40)}</div>
            <div className={styles['user-username']}>@{truncate(activeConversation?.user?.username, 30)}</div>
          </div>
        </div>
        <div className={styles['user-btn-container']}>
          <Button
            text="View Profile"
            size="small"
            link={
              activeConversation?.user && {
                href: Links.bookstore(activeConversation.user).show,
              }
            }
          />
        </div>
      </div>
    );
  }

  function renderMessage(message: Message) {
    const myMessage = message.fromId === uid;

    return (
      <div key={message.id}>
        <div
          className={classNames(styles['message-container'], {
            [styles['message-container--theirs']]: !myMessage,
            [styles['message-container--mine']]: myMessage,
          })}
        >
          <div className={styles['msg-detail-avatar']}>
            {myMessage ? (
              <UserProfileImage user={state?.user?.data} />
            ) : (
              <UserProfileImage user={activeConversation?.user} />
            )}
          </div>
          <div className={styles['msg-wrapper']}>
            <div className={styles['msg-metadata-time']}>
              {moment(message.timestamp).calendar(null, calendarConfig)}
            </div>
            <div className={styles['msg-metadata']}>
              {!message.book && !message.order && !myMessage && (
                <div className={styles['msg-metadata-name']}>{truncate(activeConversation?.user?.name, 25)}</div>
              )}
            </div>
            {message.book && (
              <div className={styles['msg-book-tile']}>
                <BookTileSmall book={message.book} source="messages" />
              </div>
            )}
            {message.order && (
              <div
                className={styles['order-card']}
                onClick={() => router.push(Links.account.purchases(message.order.id))}
              >
                <CondensedImage order={message.order} size="sm" />
                <div style={{ margin: "1rem" }} className={styles['order-status']}>
                  <StatusBadge order={message.order} />
                </div>
                <div className={styles['order-chevron']}>
                  <ChevronRightSVG />
                </div>
              </div>
            )}

            <div
              key={message.id}
              className={classNames(styles.message, {
                [styles['message--theirs']]: !myMessage,
                [styles['message--mine']]: myMessage,
              })}
            >
              <p className={styles['message-content']}>{message.message}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function renderMessageThread() {
    return (
      <InfiniteScroll
        className={styles.messages}
        onScrollEnd={handleScrollEnd}
        loadingIndicator={
          moreMsgs && (
            <>
              <div className={styles['loading-left']} />
              <div className={styles['loading-right']} />
            </>
          )
        }
      >
        {activeConversation &&
          activeConversation?.messages?.map((message) => {
            return renderMessage(message);
          })}
      </InfiniteScroll>
    );
  }

  function renderConversationInput() {
    if (!activeConversation) return;

    return (
      <div ref={inputRef} className={styles['msg-input-wrapper']}>
        <form onSubmit={handleSendMessage}>
          <div className={styles['msg-input']}>
            {book && (
              <div className={styles['input-img-book']}>
                <BookTileSmall book={book} source="messages" />
              </div>
            )}
            {order && (
              <div className={styles['input-img']} onClick={() => router.push(Links.account.purchases(order.id))}>
                <CondensedImage order={order} size="sm" />
              </div>
            )}
            <textarea
              ref={messageInputRef}
              className={styles['input-field']}
              placeholder="Send a message"
              onChange={(e) => setMessage(e.target.value)}
              value={message}
              required
            />
            <div className={styles['send-btn']}>
              <Button text="Send" size="small" type="submit" disabled={!message} />
            </div>
          </div>
        </form>
      </div>
    );
  }

  function renderMessageNavigation() {
    return (
      <div className={styles['recent-msg-container']}>
        <div className={styles['recent-msg-wrapper']}>
          <div className={styles['recent-msg-title-wrapper']}>
            <h2>Messages</h2>
            <Button size="xtra-small" style="secondary" onPress={handleNewMessage} icon={<NewMessageSVG />} />
            {/* <div
              className={styles['new-msg-icon-box']}
              onClick={handleNewMessage}
            >
              <div className={styles['new-msg-icon']}>
                <NewMessageSVG />
              </div>
            </div> */}
          </div>
          <div className={styles.search}></div>
          <InfiniteScroll
            className={styles['recent-msgs']}
            onScrollEnd={handleRecentMsgsScrollEnd}
            loadingIndicator={
              moreRecentMsgs && (
                <div className={styles['recent-msg-loading-container']}>
                  <div className={styles.details}>
                    <div className={styles.avatar} />
                    <div className={styles.name} />
                  </div>
                  <div className={styles.text} />
                  <div className={styles['text-2']} />
                </div>
              )
            }
          >
            {recentMessages?.map((msg) => (
              <MessageListItem
                message={msg}
                key={msg.chatId}
                active={activeConversation?.user?.id && msg.chatId.includes(activeConversation?.user?.id)}
                onPress={async () => {
                  router.push(
                    Links.messages.general({
                      type: 'chatId',
                      id: msg.chatId,
                    })
                  );
                  try {
                    await handleReadMessage(msg);
                  } catch (e) {
                    // err
                  }
                }}
              />
            ))}
          </InfiniteScroll>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <PageMetaData title={'Account | Messages'} />
      <div className={styles['content-container']}>
        {renderMessageNavigation()}
        <div className={styles['msg-detail-container']} onClick={setMessageInputFocus as any}>
          <div className={styles['msg-detail-wrapper']}>
            {renderEmptyState()}
            {renderActiveConversationHeader()}
            {renderMessageThread()}
            {renderConversationInput()}
            {renderConversationInput()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountMessages;

export async function getServerSideProps(ctx: GetServerSidePropsContext) {
  const authUser = await withUser(ctx);

  return {
    ...authUser,
  };
}
